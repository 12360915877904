














import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Utils from '../utils/Utils';
import Button from './Button.vue';
import gsap from 'gsap';

@Component({
	components:{
		Button,
	}
})
export default class ShareMultiplayerLink extends Vue {

	public showCopied:boolean = false;

	public get shareUrl():string {
		return window.location.href;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	/**
	 * Copies the current link to share it with people
	 */
	public shareCurrentRoom():void {
		Utils.copyToClipboard(window.location.href);
		this.showCopied = true;
		setTimeout(() => {
			this.showCopied = false;
		}, 5000);
		this.$nextTick().then(_=> {
			gsap.set(this.$refs.content, {filter:"brightness(1)"});
			gsap.from(this.$refs.content, {duration:.25, filter:"brightness(2)", ease:"Sine.easeOut"});
		})
	}

}
