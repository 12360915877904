

















import Button from "@/components/Button.vue";
import SockController, { SOCK_ACTIONS } from "@/sock/SockController";
import TwitchExtensionHelper from "@/twitch/TwitchExtensionHelper";
import Utils from "@/utils/Utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
	components:{
		Button
	}
})
export default class TwitchViewerControls extends Vue {

	@Prop({default:""})
	public section:string;

	public loading:boolean = false;
	public opened:boolean = false;
	public roundComplete:boolean = false;
	public gameComplete:boolean = false;
	public clickHandler:any;

	public get socketName():string {
		if(Utils.getRouteMetaValue(this.$route, "obsMode")) {
			return this.$store.state.twitchLogin
		}else{
			return TwitchExtensionHelper.instance.auth.channelId;
		}
	}

	public async mounted():Promise<void> {
		this.clickHandler = (e:MouseEvent) => this.onClickOutside(e);
		document.body.addEventListener("click", this.clickHandler);
		// IRCClient.instance.sendMessage("test")

		if(!Utils.getRouteMetaValue(this.$route, "obsMode")) {
			await TwitchExtensionHelper.instance.onConnect();
		}

		SockController.instance.connect();
		SockController.instance.user = {
											name:"stream",
											id:this.socketName+"_ext",
											offline:false,
											score:0,
											handicap:0,
										};
		this.onGameStateChange();
	}

	public beforeDestroy():void {
		SockController.instance.disconnect();
		document.body.removeEventListener("click", this.clickHandler);
	}

	public startGame():void {
		this.sendMessage("!start");
	}

	public endRound():void {
		this.sendMessage("!skip");
	}
	
	public nextRound():void {
		this.sendMessage("!next");
	}

	public showResults():void {
		this.sendMessage("!results");
	}

	public replay():void {
		this.sendMessage("!replay");
	}

	private async sendMessage(message:string):Promise<void> {
		let data = {
			target:this.socketName+"_ctrl",
			data:{action:SOCK_ACTIONS.SEND_TO_UID, data:message}
		};
		SockController.instance.sendMessage({action:SOCK_ACTIONS.SEND_TO_UID, data});
		this.loading = true;
		setTimeout(_=> {
			this.loading = false;
		}, 1000)
		// let params = {
		// 	token:TwitchExtensionHelper.instance.auth.token,
		// 	channel:TwitchExtensionHelper.instance.auth.channelId,
		// 	clientId:TwitchExtensionHelper.instance.auth.clientId,
		// 	message
		// }
		// try {
		// 	await Api.post("twitch/sendToChat", params);
		// }catch(e){}
		// this.loading = false;
	}

	public onClickOutside(e:MouseEvent):void {
		this.opened = false;
	}

	@Watch("$store.state.twitchGameState", { immediate: true, deep: true })
	public onGameStateChange():void {
		let gameState = this.$store.state.twitchGameState;
		if(!gameState) return;
		this.roundComplete = gameState.roundComplete
		this.gameComplete = gameState.gameComplete

	}
}
