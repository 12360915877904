



















import Utils from '@/utils/Utils';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import UserData from '../vo/UserData';
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class GroupUserList extends Vue {

	@Prop()
	public users;

	@Prop()
	public me;

	@Prop()
	public room;

	@Prop()
	public gameComplete;

	public get canKick():boolean { return this.me.id == this.room.creator; }

	public userClasses(u:UserData):string[] {
		let res = ["player"];
		if(u.id == this.me.id) res.push("me");
		if(u.id == this.room.creator && !u.offline) res.push("host");
		if(u.offline) res.push("offline");
		if(u.pass && !this.gameComplete) res.push("passed");
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	/**
	 * Compute the size of a user's bar
	 */
	public userScorePercentStyles(user:UserData):any {
		let maxScore = 0;
		for (let i = 0; i < this.room.tracksCount; i++) maxScore += i+1;
		maxScore *= this.room.gamesCount;
		let w = (user.score/maxScore*100)+"%";
		return {
			width:w,
		}
	}

	/**
	 * Called when clicking Kick button
	 */
	public onClickKick(u:UserData):void {
		Utils.confirm(this.$t('group.game.kickConfirm.title').toString(), null, this.$t('group.game.kickConfirm.description').toString())
		.then( _=> {
			this.$emit("kick", u);
		}).catch(_=>{/*don't care*/});
	}

}
