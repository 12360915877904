
































import Utils from "@/utils/Utils";
import gsap from "gsap";
import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "../Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class TwitchGameStatus extends Vue {

	public iwannaplay:boolean = false;

	public get expertMode():boolean {
		return this.$store.state.twitchExpertMode != null && this.$store.state.twitchExpertMode.length > 0;
	}

	public get playlists():{name:string,cover:string}[] {
		let list:any[] = JSON.parse(JSON.stringify(this.$store.state.twitchPlaylists));
		if(!list) return [];
		return list;
	}

	public get titleClasses():string[] {
		let res = ["type"];
		if(this.expertMode && this.titleAccepted) res.push("active");
		return res;
	}
	public get titleAccepted():boolean { return this.$store.state.twitchExpertMode.indexOf("title") > -1; }

	public get artistClasses():string[] {
		let res = ["type"];
		if(this.expertMode && this.artistAccepted) res.push("active");
		return res;
	}
	public get artistAccepted():boolean { return this.$store.state.twitchExpertMode.indexOf("artist") > -1; }

	public get obsMode():boolean {
		return Utils.getRouteMetaValue(this.$route, "obsMode") === true;
	}

	public mounted():void {
		this.onPlayChange();
	}

	public beforeDestroy():void {
	}

	public beforeEnter(el:HTMLDivElement):void {
		gsap.set(el, {opacity:1});
	}

	public enter(el:HTMLDivElement):void {
		let index = parseInt(el.dataset.index) + 1;
		gsap.from(el, {top:-20, opacity:0, duration:.5, delay:index*.1});
	}

	public leave(el:HTMLDivElement):void {
		let index = parseInt(el.dataset.index);
		gsap.to(el, {top:-20, opacity:0, duration:.5, delay:index*.1});
	}

	@Watch("iwannaplay")
	public onPlayChange():void {
		this.$store.dispatch("setVolume", this.iwannaplay? .5 : 0);
	}
}
