
















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class VolumeButton extends Vue {

	@Prop({default:.5})
	public value:number;

	@Prop({default:false})
	public twitchMode:boolean;

	@Prop({default:false})
	public horizontal:boolean;

	public expand:boolean = false;
	public dragging:boolean = false;
	public isMouseOver:boolean = false;
	public prevVolume:number = .5;
	public volume:number = .5;

	private mouseUpHandler:any;
	private mouseMoveHandler:any;

	public get mute():boolean {
		return this.volume == 0;
	}

	public get classes():string[]{
		let res = ["volumebutton"];
		if(this.expand) res.push("expand");
		if(this.twitchMode !== false) res.push("twitchMode");
		if(this.horizontal !== false) res.push("horizontal");
		return res;
	}

	public get styles():any{
		let percent = (1-this.volume) * 100;
		return {
			clipPath: "polygon(0 "+percent+"%, 100% "+percent+"%, 100% 100%, 0 100%)",
		}
	}

	public mounted():void {
		this.volume = this.prevVolume = this.$store.state.volume;

		this.mouseUpHandler = (e) => this.onRelease(e);
		this.mouseMoveHandler = (e) => this.onMove(e);
		document.addEventListener("mouseup", this.mouseUpHandler);
		document.addEventListener("touchend", this.mouseUpHandler);
		document.addEventListener("mousemove", this.mouseMoveHandler);
		document.addEventListener("touchmove", this.mouseMoveHandler);
	}

	public beforeDestroy():void {
		document.removeEventListener("mouseup", this.mouseUpHandler);
		document.removeEventListener("touchend", this.mouseUpHandler);
		document.removeEventListener("mousemove", this.mouseMoveHandler);
		document.removeEventListener("touchmove", this.mouseMoveHandler);
	}

	private toggleSound():void {
		
	}

	public onTouchStartHolder(e:TouchEvent):void {
		if(!this.isMouseOver) {
			e.preventDefault();
		}
		this.isMouseOver = true;
	}

	public onClick():void {
		if(this.volume > 0) this.prevVolume = this.volume;
		if(this.expand) {
			this.volume = this.volume > 0? 0 : this.prevVolume == 0? .5 : this.prevVolume;
		}
	}

	public onPress(e:MouseEvent):void {
		this.dragging = true;
		this.onMove(e);
	}

	public onRelease(e:MouseEvent):void {
		this.dragging = false;
		if(!this.isMouseOver) this.expand = false;
	}

	public onMove(e:MouseEvent|TouchEvent):void {
		if(!this.dragging) return;
		let arrow = <SVGElement>this.$refs.arrow;
		let bounds = arrow.getBoundingClientRect();
		if(this.horizontal !== false) {
			let mouseX = e instanceof MouseEvent? (<MouseEvent>e).clientX : (<TouchEvent>e).touches[0].clientX;
			this.volume = Math.min(1, Math.max(0, (mouseX - bounds.left)/bounds.width));
		}else{
			let mouseY = e instanceof MouseEvent? (<MouseEvent>e).clientY : (<TouchEvent>e).touches[0].clientY;
			this.volume = Math.min(1, Math.max(0, 1-(mouseY - bounds.top)/bounds.height));
		}
	}

	@Watch("isMouseOver")
	public onMouseOverChange():void {
		if(!this.isMouseOver && !this.dragging) this.expand = false;
		if(this.isMouseOver) this.expand = true;
	}

	@Watch("volume")
	public onVolumeChange():void {
		this.$store.dispatch("setVolume", this.volume);
	}

}
