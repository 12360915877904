




























import Button from '@/components/Button.vue';
import Store from "@/store/Store";
import Config from '@/utils/Config';
import SpotifyAPI from '@/utils/SpotifyAPI';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class Home extends Vue {

	@Prop()
	public from:string;

	public get loggedIn() {
		return this.$store.state.loggedin;
	}

	public get authUrl():string {
		if(this.from) {
			Store.set("redirect", this.from);
		}
		return SpotifyAPI.instance.getAuthUrl();
	}

	public get tracksCount():number {
		return Config.MAX_TRACK_COUNT;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
