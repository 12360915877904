
































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import SpotifyAPI from '@/utils/SpotifyAPI';
import InfiniteList from '@/components/InfiniteList';
import TrackData from '@/vo/TrackData';
import SearchTrackResultItem from '@/components/SearchTrackResultItem.vue';
import Button from '@/components/Button.vue';
import Config from '@/utils/Config';
import AudioPlayer from '../components/AudioPlayer';
import VolumeButton from '../components/VolumeButton.vue';
import Utils from '../utils/Utils';
import AutoCompleteForm from '../components/AutoCompleteForm.vue';

@Component({
	components:{
		Button,
		VolumeButton,
		AutoCompleteForm,
		SearchTrackResultItem,
	}
})
export default class MixCreator extends Vue {

	public testing:boolean = false;
	public expandHelp:boolean = false;
	public loadingAudio:boolean = false;
	public selectedTracks:TrackData[] = [];
	
	private audioPlayer:AudioPlayer;

	public get tracksCount():number { return Config.MAX_TRACK_COUNT; }

	public mounted():void {
		this.audioPlayer = new AudioPlayer(Config.MAX_TRACK_COUNT);
		this.audioPlayer.onLoadComplete = () => this.loadingAudio = false;
	}

	public beforeDestroy():void {
		this.audioPlayer.stopAll();
		this.audioPlayer.dispose();
	}

	/**
	 * Removes a track from the list
	 */
	public removeTrack(data:TrackData):void {
		for (let i = 0; i < this.selectedTracks.length; i++) {
			const t = this.selectedTracks[i];
			if(t.id == data.id) {
				this.selectedTracks.splice(i, 1);
				break;
			}
		}
		this.audioPlayer.stopTrack(data);
	}

	/**
	 * Stops a specific track
	 */
	public stopTrack(t:TrackData):void {
		t.isPlaying = false;
		this.audioPlayer.stopTrack(t);
		for (let i = 0; i < this.selectedTracks.length; i++) {
			if(this.selectedTracks[i].isPlaying) return;
		}
		this.testing = false;
	}

	/**
	 * Start mix testing
	 */
	public testMix():void {
		if(this.testing) {
			this.audioPlayer.stopAll();
			this.testing = false;
			return;
		}
		this.testing = true;
		this.loadingAudio = true;
		this.audioPlayer.populate(this.selectedTracks);
		for (let i = 0; i < this.selectedTracks.length; i++) {
			this.selectedTracks[i].isPlaying = true;
		}
	}

	/**
	 * Submits the mix
	 */
	public submitMix():void {
		let ids = this.selectedTracks.map(t => t.id);
		this.$router.push({name:"player/tracks", params:{tracksids:ids.join(",")}});
	}

	@Watch("$store.state.volume", {immediate: true, deep:true})
	public onVolumeChange(a, b):void {
		if(!this.audioPlayer) return;
		this.audioPlayer.volume = this.$store.state.volume;
	}

	/**
	 * Called when a track is selected on auto complete form
	 */
	public onSelectTrack(track:TrackData):void {
		this.selectedTracks.push(track);
	}

}
