







































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class IncrementForm extends Vue {

	@Prop()
	public title:string;

	@Prop()
	public value:number;

	@Prop({default:1})
	public minValue:number;

	@Prop({default:30})
	public maxValue:number;

	@Prop({default:1})
	public step:number;

	@Prop({default:false})
	public tenStep:boolean;
	
	public inputId:string = "incrementInput_"+Math.round(Math.random() * 999999);
	public incInterval:number;
	public valueLocal:number = 0;

	public mounted():void {
		this.valueLocal = this.value;
	}

	public beforeDestroy():void {
		
	}

	@Watch("value")
	private changeValue():void {
		this.valueLocal = this.value;
	}

	@Watch("valueLocal")
	private changeCount():void {
		this.valueLocal = Math.max(this.minValue, Math.min(this.maxValue, this.valueLocal));
		this.$emit("input", this.valueLocal);
	}

	public onMouseWheel(e:WheelEvent):void {
		let delta = e.deltaY? e.deltaY : e.deltaX;
		if(delta > 0) this.valueLocal-=this.step;
		if(delta < 0) this.valueLocal+=this.step;
		this.valueLocal = Math.round(this.valueLocal*100)/100;//Prevents fucked up JS number rounding to create values like 1.000000001
		e.preventDefault();
	}

	public startIncrement(inc:number):void {
		this.valueLocal += inc;
		this.valueLocal = Math.round(this.valueLocal*100)/100;//Prevents fucked up JS number rounding to create values like 1.000000001
		clearInterval(this.incInterval);
		this.incInterval = setInterval(_=> { this.valueLocal += inc; }, 100);
	}

	public stopIncrement(e:MouseEvent|TouchEvent):void {
		clearInterval(this.incInterval);
		e.preventDefault();
	}

}
