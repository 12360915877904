












import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Utils from '../utils/Utils';

@Component({
	components:{}
})
export default class SimpleLoader extends Vue {

	@Prop({default:"Loading..."})
	public label:string;

	@Prop()
	public theme:string;

	@Prop({default:false})
	public big:boolean;

	public localColor:string = null;

	public get classes():string[] {
		let res = ["SimpleLoader"];
		if(this.big !== false) res.push("big");
		return res;
	}

	public get bgLoaderStyle():any {
		return {
			fill:this.localColor,
		}
	}

	public get labelStyle():any {
		return {
			backgroundColor:this.localColor,
		}
	}

	public mounted():void {
		if(!this.theme) {
			this.localColor = Utils.getLessVars().mainColor_warn;
		}else{
			this.localColor = Utils.getLessVars()[this.theme];
		}
	}

	public beforeDestroy():void {
		
	}

}
