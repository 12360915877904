






















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import PlaylistData from '@/vo/PlaylistData';
import Button from '@/components/Button.vue';
import Slider from '@/components/Slider.vue';
import Config from '../utils/Config';

@Component({
	components:{
		Button,
		Slider,
	}
})
export default class PlaylistSelectorFooter extends Vue {

	@Prop({default:null})
	public playlists:PlaylistData[];
	@Prop()
	public mode:string;

	public tracksCount:number = 4;

	public get maxTracks():number { return Config.MAX_TRACK_COUNT; }

	public get totalTracks():number {
		let res = 0;
		for (let i = 0; i < this.playlists.length; i++) {
			const p = this.playlists[i];
			res += p.tracks.length;
		}
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public startBlindtest():void {
		this.$emit("start");
	}

}
