





















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import RoomData from '../vo/RoomData';

@Component({
	components:{}
})
export default class ExpertModeState extends Vue {

	@Prop()
	public data:string[];

	public get acceptArtist():boolean {
		return this.data.indexOf('artist')>-1
	}

	public get acceptTitle():boolean {
		return this.data.indexOf('title')>-1
	}

	public classes(accept:boolean):string[] {
		let res = ["state"];
		if(!accept) res.push("refuse");
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
