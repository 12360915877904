












import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import SocketEvent from '../vo/SocketEvent';
import SockController, { SOCK_ACTIONS } from '../sock/SockController';
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class ChatWindow extends Vue {

	public messages:any[] = [];
	public opened:boolean = true;

	private messageHandler:any;

	public getClasses(m):string[] {
		let res = ["line"];
		if(m.user.id == this.$store.state.userGroupData.id) res.push("isme");
		return res;
	}

	public mounted():void {
		this.messageHandler = (e:SocketEvent) => this.onMessage(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.CHAT_MESSAGE, this.messageHandler);
	}

	public beforeDestroy():void {
		SockController.instance.removeEventListener(SOCK_ACTIONS.CHAT_MESSAGE, this.messageHandler);
	}

	private onMessage(e:SocketEvent):any {
		this.messages.push(e.data);
		if(this.messages.length > 100) this.messages.shift();
		this.scrollToBottom();
	}

	@Watch("opened")
	private onOpenToggle():void {
		this.scrollToBottom();
	}

	private async scrollToBottom():Promise<void> {
		await this.$nextTick();
		let list = <HTMLDivElement>this.$refs.list;
		if(!list) return;
		list.scrollTo(0, list.scrollHeight);
	}

}
