










import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import TrackData from '@/vo/TrackData';

@Component({
	components:{}
})
export default class SearchTrackResultItem extends Vue {

	@Prop()
	public data:TrackData;
	@Prop()
	public selected:boolean;

	public get picture():string {
		if(this.data.picture) return this.data.picture;
		return require("@/assets/icons/song.svg");
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
