















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button
	}
})
export default class NoPlaylist extends Vue {

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
