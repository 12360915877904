










































































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Api from '../utils/Api';
import RoomData from '../vo/RoomData';
import Button from '../components/Button.vue';
import UserData from '../vo/UserData';
import SockController, { SOCK_ACTIONS } from '../sock/SockController';
import SocketEvent from '../vo/SocketEvent';
import IncrementForm from '../components/IncrementForm.vue';
import ShareMultiplayerLink from '../components/ShareMultiplayerLink.vue';
import ExpertModeForm from '../components/ExpertModeForm.vue';
import SimpleLoader from '../components/SimpleLoader.vue';
import GroupLobbyUser from '../components/GroupLobbyUser.vue';
import GameParams from "@/components/GameParams.vue";

@Component({
	components:{
		Button,
		GameParams,
		SimpleLoader,
		IncrementForm,
		ExpertModeForm,
		GroupLobbyUser,
		ShareMultiplayerLink,
	}
})
export default class GroupLobby extends Vue {

	@Prop()
	public id:string;

	public gamesCount:number = 5;
	public tracksCount:number = 4;
	public gameDuration:number = 60;
	public acceptAlbum:boolean = false;
	public expertMode:string[] = null;
	public showCopied:boolean = false;
	public loading:boolean = true;
	public joining:boolean = false;
	public serverReboot:boolean = false;
	public room:RoomData = null;
	public userName:string = "";

	public joinHandler:any;
	public leaveHandler:any;
	public startGameHandler:any;
	public handicapHandler:any;
	public updateUserNameHandler:any;
	public serverRebootHandler:any;

	//Sort playlists by name size for a better looking list rendering
	public get sortedPlaylists():any[] {
		return this.room.playlists.sort((a, b) => {
			if(a.name > b.name) return 1;
			if(a.name < b.name) return -1;
			return 0;
		})
	}

	public get isHost():boolean {
		if(!this.me) return false;
		return this.room.creator == this.me.id;
	}

	public get me():UserData {
		return this.$store.state.userGroupData;
	}

	public get hostName():string {
		for (let i = 0; i < this.room.users.length; i++) {
			if(this.room.creator == this.room.users[i].id) {
				return this.room.users[i].name;
			}
		}
		return null;
	}

	public mounted():void {
		this.joinHandler = (e) => this.onJoin(e);
		this.leaveHandler = (e) => this.onLeave(e);
		this.startGameHandler = (e) => this.onStartGame(e);
		this.handicapHandler = (e) => this.onHandicap(e);
		this.updateUserNameHandler = (e) => this.onUserName(e);
		this.serverRebootHandler = (e) => this.onServerReboot(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.JOIN_ROOM, this.joinHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.LEAVE_ROOM, this.leaveHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.START_GROUP_GAME, this.startGameHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.UPDATE_HANDICAP, this.handicapHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.UPDATE_USERNAME, this.updateUserNameHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.SERVER_REBOOT, this.serverRebootHandler);
		this.loadDetails();
	}

	public beforeDestroy():void {
		//Cleanup listeners
		SockController.instance.removeEventListener(SOCK_ACTIONS.JOIN_ROOM, this.joinHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.LEAVE_ROOM, this.leaveHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.START_GROUP_GAME, this.startGameHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.UPDATE_HANDICAP, this.handicapHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.UPDATE_USERNAME, this.updateUserNameHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.SERVER_REBOOT, this.serverRebootHandler);
	}

	/**
	 * Load group's details
	 */
	public async loadDetails():Promise<void> {
		try {
			let res = await Api.get("group/details", {roomId:this.id});
			this.room = res.room;
		}catch(error) {
			this.$store.dispatch("alert", error.message);
			if(this.$store.state.loggedin) {
				this.$router.push({name:"playlists", params:{mode:"multi"}});
			}else{
				this.$router.push({name:"home"});
			}
			return;
		}
		this.loading = false;
		if(this.me) {
			this.onSubmit();//Auto join room
		}
		if(this.room) {
			SockController.instance.groupId = this.room.id;
		}
	}

	/**
	 * Called when subitting username form
	 */
	public async onSubmit():Promise<void> {
		this.joining = true;
		var res;
		let data:any = {
				username:this.userName,
				roomId:this.room.id
			};
		if(this.me) {
			delete data.username;
			data.user = this.me;
		}
		try {
			res = await Api.post("group/join", data);
			this.room = res.room;
		}catch(error) {
			this.joining = false;
			this.$store.dispatch("alert", error.message);
			return;
		}
		res.me.offline = false;
		this.$store.dispatch("setUserGroupData", res.me);
		this.joining = false;
		if(this.room.currentTracks) {
			this.onStartGame();
		}
		if(this.room) {
			SockController.instance.groupId = this.room.id;
		}

		if(this.$route.meta.restartMode) {
			SockController.instance.sendMessage({action:SOCK_ACTIONS.RESTART_GROUP_GAME, data:{roomId:this.room.id}})
		}
	}

	/**
	 * Called when someone joins the room
	 */
	public onJoin(e:SocketEvent):void {
		if(!this.room) return;
		let found = false;
		for (let i = 0; i < this.room.users.length; i++) {
			if(this.room.users[i].id == e.data.user.id) {
				found = true;
				this.room.users[i].offline = false;
			}
		}
		if(!found) {
			this.room.users.push(e.data.user);
		}
	}
	
	/**
	 * Called when someone leaves the room
	 */
	public onLeave(e:SocketEvent):void {
		if(!this.room) return;
		for (let i = 0; i < this.room.users.length; i++) {
			const u = this.room.users[i];
			if(u.id == e.data.user.id) {
				this.room.users[i].offline = true;
			}
		}
	}

	/**
	 * Called when socket tells to start the game
	 */
	public onStartGame(e?:SocketEvent):void {
		let data = e? e.data : this.room;
		this.$store.dispatch("setGroupRoomData", data);//This is only used to transmit data to game view
		this.$router.push({name:"group/play"});
	}

	/**
	 * Called when a user's handicap is updated
	 */
	public onHandicap(e:SocketEvent):void {
		for (let i = 0; i < this.room.users.length; i++) {
			const u = this.room.users[i];
			if(u.id == e.data.user.id) {
				Vue.set(u, "handicap", e.data.handicap);
			}
		}
	}

	/**
	 * Called when a user's name is updated
	 */
	public onUserName(e:SocketEvent):void {
		for (let i = 0; i < this.room.users.length; i++) {
			const u = this.room.users[i];
			if(u.id == e.data.user.id) {
				Vue.set(u, "name", e.data.user.name);
			}
		}
	}

	/**
	 * Called when clicking "start" button.
	 * It sends a socket event to all users including self
	 * See onStartGame() method
	 */
	public startGame():void {
		this.room.tracksCount = this.tracksCount;
		this.room.gamesCount = this.gamesCount;
		this.room.gameDuration = this.gameDuration;
		this.room.acceptAlbum = this.acceptAlbum;
		this.room.expertMode = this.expertMode;
		Api.post("group/update", {room:this.room});
		SockController.instance.sendMessage({action:SOCK_ACTIONS.START_GROUP_GAME, includeSelf:true, data:this.room});
	}

	/**
	 * Handicap of a user updated
	 */
	public onUpdateUserHandicap(user:UserData, handicap:number):void {
		user.handicap = handicap;
		SockController.instance.sendMessage({action:SOCK_ACTIONS.UPDATE_HANDICAP, data:{user:user, handicap:handicap, groupId:this.room.id}});
	}

	/**
	 * Called when a user updates her/his nickname
	 */
	public onUpdateUserName(user:UserData):void {
		this.$store.dispatch("setUserName", user.name);
		SockController.instance.sendMessage({action:SOCK_ACTIONS.UPDATE_USERNAME, data:{user:user, groupId:this.room.id}});
	}

	/**
	 * Called when server has reboot
	 */
	public onServerReboot(e:SocketEvent):void {
		this.serverReboot = true;
	}

}
