
















import Button from "@/components/Button.vue";
import Config from "@/utils/Config";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class TwitchBroadcaster extends Vue {

	public get url():string {
		return Config.BASE_URL+this.$router.resolve({name:"twitch"}).href.replace("#", "");
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
