
















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class ExpertModeForm extends Vue {

	@Prop()
	public value:"artist"|"title"[];

	private isMount:boolean = false;
	private enabled:boolean = false;
	private acceptTitle:boolean = true;
	private acceptArtist:boolean = true;

	public mounted():void {
		this.isMount = true;
		this.onValueChanged();
		this.isMount = false;
	}

	public beforeDestroy():void {
		
	}
	@Watch("value")
	private onValueChanged():void {
		if(this.value && this.value.length > 0) {
			this.acceptArtist = false;
			this.acceptTitle = false;
			for (let i = 0; i < this.value.length; i++) {
				if(this.value[i] !== null) this.enabled = true;
				if(this.value[i] == "artist") this.acceptArtist = true;
				if(this.value[i] == "title") this.acceptTitle = true;
			}
		}else if(this.isMount){
			this.enabled = false;
		}
	}

	@Watch("acceptArtist")
	private onChangeTitle():void { this.update(); }
	@Watch("acceptTitle")
	private onChangeArtist():void { this.update(); }
	@Watch("enabled")
	private onChangeEnabled():void { this.update(); }

	private update():void {
		if(!this.enabled) {
			this.$emit("input", null);
			return;
		}
		let res = [];
		if(this.acceptTitle) res.push("title");
		if(this.acceptArtist) res.push("artist");
		this.$emit("input", res);
	}

}
