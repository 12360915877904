













import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class ToggleBlock extends Vue {

	@Prop()
	public title:string;

	@Prop()
	public icon:string;

	@Prop({default:null})
	public closed:boolean;

	@Prop({default:true})
	public enabled:boolean;

	@Prop({default:false})
	public small:boolean;

	public showContent:boolean = true;

	public get classes():string[] {
		let res = ["toggleblock"];
		if(this.enabled) res.push("enabled");
		if(this.showContent) res.push("open");
		if(this.small !== false) res.push("small");
		if(!this.enabled && this.closed) res.push("disabled");
		return res;
	}

	public mounted():void {
		this.showContent = this.closed == null;
	}

	public beforeDestroy():void {
		
	}

	public open():void {
		this.showContent = true;
	}

	public close():void {
		this.showContent = false;
	}

	public toggle():void {
		if(!this.enabled) return;
		this.showContent = !this.showContent;
	}

}
