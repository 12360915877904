











import gsap from "gsap";
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class BouncingLoader extends Vue {

	@Prop()
	public icon:string;

	@Prop()
	public label:string;

	public mounted():void {
		gsap.to(this.$refs.icon, {duration: 1, ease:"Elastic.easeIn", scale:1.2, repeat:-1}).yoyo(true);
	}

	public beforeDestroy():void {
		gsap.killTweensOf(this.$refs.icon);
	}

}
