














import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import PlaylistData from '../vo/PlaylistData';

@Component({
	components:{}
})
export default class SearchPlaylistResultItem extends Vue {

	@Prop()
	public data:PlaylistData;

	@Prop()
	public selected:boolean;

	public get picture():string {
		if(this.data.cover) return this.data.cover;
		return require("@/assets/icons/playlist.svg");
	}

	public get owner():string {
		return this.$store.state.i18n.t('playlists.owner', {owner:this.data.owner}).toString();
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
