













import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import gsap from 'gsap';

@Component({
	components:{}
})
export default class NeedInteractionLayer extends Vue {

	public mounted():void {
		this.$nextTick().then(()=>{
			gsap.from(this.$refs.icon, {duration: 1, ease:"Elastic.easeOut", scale:1.2, repeat:-1}).yoyo(true);
		})
	}

	public beforeDestroy():void {
		
	}

}
