


















import PlaylistData from '@/vo/PlaylistData';
import { Component, Prop, Vue } from "vue-property-decorator";
import Utils from '../utils/Utils';
import Button from './Button.vue';

@Component({
	components:{
		Button
	}
})
export default class PlayListEntry extends Vue {

	@Prop()
	public data:PlaylistData;

	@Prop({default:false})
	public reduced:boolean;

	public selected:boolean = false;

	public get classes():string[] {
		let res = ["playlistentry"];
		if(this.selected) res.push("selected");
		if(this.reduced !== false) res.push("disabled");
		if(this.data.searchOrigin) res.push("fromSearch");
		return res;
	}


	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public onClick(event):void {
		if(this.data.processingTracks) {
			this.selected = false;
		}else{
			this.selected = !this.selected
		}
		this.$emit('select');
	}

	public onClickDelete(event:MouseEvent|TouchEvent):void {
		event.stopPropagation();//Avoid selection event being fired
		Utils.confirm(this.$t("playlists.deleteConfirm").toString())
		.then(_=> {
			this.$emit('delete', this.data);
		}).catch(e=>{/*ignore*/})
	}

}
