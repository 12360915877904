














import Button from "@/components/Button.vue";
import Api from "@/utils/Api";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class TwitchIntro extends Vue {

	public twitchName:string = "";
	public error:boolean = false;
	public success:boolean = false;
	public sendingTwitchName:boolean = false;

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
