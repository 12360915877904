






import { Component, Inject, Model, Prop, Vue, Watch, Provide } from 'vue-property-decorator'
import gsap from 'gsap';

@Component
export default class AlertView extends Vue {

	public message:string = "";

	public mounted():void {
		
	}

	@Watch("$store.state.alert")
	public onWatchAlert():void {
		let mess = this.$store.state.alert;
		if(mess && mess.length > 0) {
			this.message = mess;
			this.$nextTick().then(_=> {
				this.$el.removeAttribute("style");
				gsap.from(this.$el, {duration:.3, height:0, paddingTop:0, paddingBottom:0, ease:"back.out"});
				setTimeout(_=> this.close(), mess.length/10 * 1000);
			});
		}else{
			this.$nextTick().then(_=> {
				gsap.to(this.$el, {duration:.3, height:0, paddingTop:0, paddingBottom:0, ease:"back.in", onComplete:()=> {
					this.message = null;
				}});
			});
		}
	}

	public close():void {
		this.$store.dispatch("alert", null);
	}
}
