









import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class Slider extends Vue {

	@Prop()
	public label:string;
	
	@Prop({default:6})
	public value:number;
	
	@Prop({default:0})
	public min:number;
	
	@Prop({default:10})
	public max:number;

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public classes(index:number):string[] {
		let res = ["item"];
		if(this.value == index) res.push("selected");
		return res
	}

	public clicItem(index:number):void {
		this.$emit("input", index);
	}

}
