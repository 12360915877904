







import BouncingLoader from "@/components/BouncingLoader.vue";
import Button from "@/components/Button.vue";
import SockController, { SOCK_ACTIONS } from "@/sock/SockController";
import TwitchMessageType from "@/twitch/TwitchMessageType";
import SocketEvent from "@/vo/SocketEvent";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components:{
		Button,
		BouncingLoader,
	}
})
export default class TwitchOBS extends Vue {
	
	public ready:boolean = false;
	public gameStarted:boolean = false;
	public messageHandler:any;
	public zoom:number = 1;

	public get styles():any {
		return {
			fontSize:this.zoom+"em",
		}
	}

	public mounted():void {
		this.ready = true;
		this.messageHandler = (e:SocketEvent) => this.onMessage(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.SEND_TO_UID, this.messageHandler);
	}

	public beforeDestroy():void {
		SockController.instance.removeEventListener(SOCK_ACTIONS.SEND_TO_UID, this.messageHandler);
	}

	public onMessage(e:SocketEvent):void {
		if(typeof e.data != "object") return;
		
		switch(e.data.actionType) {
			case TwitchMessageType.PLAYLISTS:
				// console.log("SET PLAYLISTS");
				this.zoom = e.data.state.zoom;
				this.$store.dispatch("setTwitchPlaylists", e.data.state.playlists);
				this.$store.dispatch("setTwitchExpertMode", e.data.state.expert);
				this.$store.dispatch("setTwitchGameState", null);
				this.$store.dispatch("setTwitchLeaderboard", null);
				this.gameStarted = true;
				break;
			case TwitchMessageType.ROUND_STATE:
				// console.log("SET ROUND STATE");
				this.zoom = e.data.state.zoomLevel;
				this.$store.dispatch("setTwitchPlaylists", null);
				this.$store.dispatch("setTwitchExpertMode", null);
				this.$store.dispatch("setTwitchGameState", e.data.state);
				this.$store.dispatch("setTwitchLeaderboard", null);
				this.gameStarted = true;
				break;
			case TwitchMessageType.LEADERBOARD:
				// console.log("SET LEADERBOARD");
				this.$store.dispatch("setTwitchPlaylists", null);
				this.$store.dispatch("setTwitchExpertMode", null);
				this.$store.dispatch("setTwitchGameState", null);
				this.$store.dispatch("setTwitchLeaderboard", e.data.state);
				this.gameStarted = true;
				break;
			case TwitchMessageType.BROADCASTER_CONTROL:
				// console.log("BROADCASTER_CONTROL");
				break;
			case TwitchMessageType.SET_ZOOM_LEVEL:
				// console.log("SET_ZOOM_LEVEL");
				this.zoom = e.data.zoom
				break;
			case TwitchMessageType.CHANGE_VOLUME:
				this.$store.dispatch("setVolume", e.data.volume);
				break;
			case TwitchMessageType.SET_TRACK_PLAY_STATE:
				//This is cpatured in the TwitchViewerGame clas
				break;
			default:
				console.error("Received a broadcast message with no handled \"actionType\" value");
				console.log(e.data);
		}

		// if(this.$route.name == "twitchext/broadcaster") {
		// 	this.redirect();
		// }
	}

}
