















































import Utils from "@/utils/Utils";
import TrackData from '@/vo/TrackData';
import gsap from "gsap";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Button from "./Button.vue";

@Component({
	components:{
		Button,
	}
})
export default class TrackEntry extends Vue {

	@Prop()
	public data:TrackData;

	@Prop({default:false})
	public forceReveal:boolean;

	@Prop({default:false})
	public small:boolean;

	@Prop({default:true})
	public canReplay:boolean;

	@Prop({default:false})
	public burstStars:boolean;

	@Prop({default:false})
	public acceptAlbum:boolean;
	
	public playing:boolean = false;
	public showJSONBt:boolean = false;

	public get classes():string[] {
		let res = ["trackentry"];
		if(this.small !== false) res.push("small");
		if(this.reveal) res.push("enabled");
		if(this.data.loadFail) res.push("error");
		if(this.forceReveal && !this.data.enabled) res.push("forcedReveal");
		if(this.data.highlight === true) res.push("highlight");
		return res;
	}

	public get reveal():boolean {
		return this.data.enabled || this.forceReveal;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public allowManualStop():void {
		this.canReplay = true;
		this.playing = true;
	}

	public onClickStop():void {
		this.$emit('stop', this.data);
		this.playing = false;
	}

	public onClickPlay():void {
		this.playing = true;
		this.$emit('play', this.data);
	}

	@Watch("data.enabled")
	public onRevealChange():void {
		if(this.burstStars && this.data.enabled) {
			this.burstParticles();
		}
	}

	public burstParticles():void {
		let stars = <Element[]>this.$refs.star;
		let bounds = this.$el.getBoundingClientRect();
		for (let i = 0; i < stars.length; i++) {
			const s = stars[i];
			gsap.killTweensOf(s);
			let px = Math.random() * bounds.width - 30;
			let py = Math.random() * bounds.height - 30;
			gsap.set(s, {opacity:1, x:px, y:py, scale:Math.random()*1 + .5});
			gsap.to(s, {opacity:0,
						rotation:(Math.random()-Math.random()) * Math.PI * 2.5+"rad",
						x:px + (Math.random()-Math.random()) * 200,
						y:py + (Math.random()-Math.random()) * 100,
						scale:0,
						duration:1.25});
		}
		setTimeout(_=> {
			//Reset stars to avoid page overflow on small screens
			for (let i = 0; i < stars.length; i++) {
				const s = stars[i];
				gsap.set(s, {opacity:1, x:0, y:0, scale:0});
			}
		},1500)
	}

	public copyJSON():void {
		Utils.copyToClipboard(JSON.stringify(this.data));
	}

}
