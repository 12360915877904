





































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';
import gsap from 'gsap';

@Component({
	components:{
		Button,
	}
})
export default class TrackAnswerForm extends Vue {

	@Prop({default:true})
	public showForm:boolean;

	@Prop({default:true})
	public canGuess:boolean;

	@Prop({default:true})
	public showShare:boolean;

	@Prop({default:true})
	public multiplayerMode:boolean;

	@Prop()
	public shareUrl:string;

	public guess:string = "";
	public error:boolean = false;
	public success:boolean = false;
	public anonMode:boolean = false;
	public focusHandler:any;

	public get classes():string[] {
		let res = ["trackanswerform"]
		if(this.error) res.push("error");
		if(this.success) res.push("success");
		if(this.multiplayerMode) res.push("multiplayermode");
		return res;
	}

	public mounted():void {
		this.focusHandler = (e)=>this.onFocus();
		document.addEventListener("keydown", this.focusHandler);
		document.addEventListener("mouseup", this.focusHandler);
	}

	public beforeDestroy():void {
		document.removeEventListener("keydown", this.focusHandler);
		document.removeEventListener("mouseup", this.focusHandler);
	}

	private onFocus():void {
		if(!this.$refs.input) return;
		(<HTMLInputElement>this.$refs.input).focus();
	}

	public shake():void {
		this.error = true;
		gsap.killTweensOf(this.$refs.inputLine);
		gsap.set(this.$refs.inputLine, {left:0});
		gsap.to(this.$refs.inputLine, {duration:.05, left:"-10px", repeat:5, ease:"Sine.easeInOut", onComplete:()=> {
				this.error = false
		}}).yoyo(true);
	}

	public shine():void {
		this.guess = "";
		// gsap.set(this.$refs.input, {filter:"brightness(1)"});
		// gsap.from(this.$refs.input, {duration:.25, filter:"brightness(2)", ease:"Sine.easeOut"});
		this.burstParticles();
		this.success = true;
		gsap.to(this.$refs.inputLine, {duration:.1, scale:"1.025", repeat:1, ease:"Sine.easeInOut", onComplete:()=> {
			this.success = false;
		}}).yoyo(true);
	}

	public onSubmitGuess(event):void {
		if(this.canGuess) {
			this.$emit("guess", this.guess);
		}

		if(!this.anonMode) {
			this.sendChatMessage();
		}
	}

	public onKeyUp(event:KeyboardEvent):void {
		//Manage ctrl+Enter to submit chat message
		if(event.keyCode == 13 && event.ctrlKey && this.guess.length > 0) {
			this.sendChatMessage();
		}
		//Escape key
		if(event.keyCode == 27) {
			this.guess = "";
		}
	}

	public sendChatMessage():void {
		if(this.guess.trim().length == 0) return;
		this.$emit("sendToChat", this.guess);
		this.guess = "";
	}

	public onShowAnswers():void {
		this.$emit("showAnswers");
	}

	public onShareList():void {
		this.$emit("share");
		this.$nextTick().then(_=> {
			gsap.set(this.$refs.share, {filter:"brightness(1)"});
			gsap.from(this.$refs.share, {duration:.25, filter:"brightness(2)", ease:"Sine.easeOut"});
		})
	}

	public burstParticles():void {
		let stars = <Element[]>this.$refs.star;
		for (let i = 0; i < stars.length; i++) {
			const s = stars[i];
			gsap.killTweensOf(s);
			let px = Math.random() * 400 + 40;
			let py = Math.random() * 40 - 40;
			gsap.set(s, {opacity:1, x:px, y:py, scale:Math.random()*1 + .5});
			gsap.to(s, {opacity:0, rotation:(Math.random()-Math.random()) * Math.PI * 2.5+"rad", x:px + (Math.random()-Math.random()) * 200, y:py + (Math.random()-Math.random()) * 100, scale:0, duration:1.25});
		}
	}

}
