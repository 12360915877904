






















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import UserData from '../vo/UserData';
import IncrementForm from './IncrementForm.vue';
import SockController, { SOCK_ACTIONS } from '../sock/SockController';
import Button from './Button.vue';

@Component({
	components:{
		Button,
		IncrementForm
	}
})
export default class GroupLobbyUser extends Vue {

	@Prop()
	public data:UserData;

	@Prop()
	public me:UserData;
	
	@Prop()
	public isHost:boolean;

	public hover:boolean = false;
	public editName:boolean = false;
	public handicap:number = 0;

	public get isMe():boolean {
		return this.me && this.data.id == this.me.id;;
	}

	public get classes():string[] {
		let res = ["label"];
		if(this.isMe) res.push("me");
		if(this.data.offline) res.push("offline");
		else if(this.isHost) res.push("host");
		// if(this.data.offline && !isMe) res.push("offline");
		return res;
	}

	public mounted():void {
	}

	public beforeDestroy():void {
		
	}

	@Watch("handicap")
	private onhandicapUpdate():void {
		this.$emit("update", this.data, this.handicap);
	}

	@Watch("hover")
	private onHover():void {
		this.handicap = this.data.handicap;
	}

	private clickName():void {
		if(this.isMe) {
			this.editName = true;
		}
	}

	private submitName():void {
		this.$emit("updateName", this.data);
		this.editName = false;
	}

}
